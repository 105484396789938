<script lang="ts" setup>
useHead({
  title: "Dashboard",
});
</script>

<template>
  <div>
    <AppHeader />
    <NuxtLoadingIndicator />
    <main>
      <slot />
    </main>
  </div>
</template>

<style lang="scss" scoped>
.nuxt-loading-indicator {
  @apply sticky z-40 -mt-[3px] bg-gradient-to-r from-sapphire to-light-royal-blue #{!important};
}
</style>
